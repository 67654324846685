import _sha from "sha.js";
import _buffer from "buffer";
import _jsonStableStringify from "json-stable-stringify";
var exports = {};
var createHash = _sha;
var Buffer = _buffer.Buffer;
var stringify = _jsonStableStringify;

exports = function hash(str, alg, format) {
  str = "string" === typeof str ? str : Buffer.isBuffer(str) ? str : stringify(str);
  return createHash(alg || "sha1").update(str, Buffer.isBuffer(str) ? null : "utf8").digest(format || "hex");
};

export default exports;